import React from "react";
import "./RemoveLayer.css";

class RemoveLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="removeLayer_trashCan">
        <div className="tooltiptext"> Remove Layer</div>
      </div>
    );
  }
}

export default RemoveLayer;
