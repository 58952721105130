/*
 * Add Event listeners for canvas interaction
 */
export function addCanvasEventListeners(space) {
  /*
   * Get Canvas
   */

  document.body.addEventListener("keydown", trackSpacebarDown.bind(this));
  document.body.addEventListener("keyup", trackSpacebarUp.bind(this));

  document.body.addEventListener("mousedown", trackClickDown.bind(this));
  document.body.addEventListener("mouseup", trackClickUp.bind(this));

  document.addEventListener(
    "visibilitychange",
    trackVisibilityChange.bind(this)
  );
  window.addEventListener("blur", trackBlur.bind(this));

  document.body.addEventListener("mousemove", moveElements.bind(this));

  document.body.addEventListener("wheel", zoomElements.bind(this), {
    passive: false
  });
}

export function removeCanvasEventListeners() {
  
  document.body.removeEventListener("keydown", trackSpacebarDown.bind(this));
  document.body.removeEventListener("keyup", trackSpacebarUp.bind(this));

  document.body.removeEventListener("mousedown", trackClickDown.bind(this));
  document.body.removeEventListener("mouseup", trackClickUp.bind(this));

  document.removeEventListener(
    "visibilitychange",
    trackVisibilityChange.bind(this)
  );
  window.removeEventListener("blur", trackBlur.bind(this));

  document.body.removeEventListener("mousemove", moveElements.bind(this));

  document.body.removeEventListener("wheel", zoomElements.bind(this), {
    passive: false
  });
}

/*
 * Functions to trigger State updates
 */
function trackSpacebarDown(e) {
  if (e.key === " " || e.key === "Spacebar") {
    this.setState({
      meta: true,
      metaClick: this.space.pointer,
      cursor: "grab"
    });
  }
}

function trackSpacebarUp(e) {
  if (e.key === " " || e.key === "Spacebar") {
    this.setState({ meta: false, cursor: "default" });
  }
}

function trackClickDown() {
  if (this.state.meta) {
    this.setState({ metaDown: true, metaClick: this.space.pointer });
  }
}

function trackClickUp() {
  if (this.state.meta) {
    this.setState({ metaDown: false });
  }
}

function trackVisibilityChange() {
  this.setState({ metaDown: false, cursor: "default" });
}

function trackBlur() {
  this.setState({ metaDown: false, cursor: "default" });
}

function moveElements() {
  if (this.state.meta && this.state.metaDown) {
    const metaLocation = this.state.metaLocation.$add(
      this.space.pointer.$subtract(this.state.metaClick)
    );
    //Update UI Boxes
    this.setState({ metaClick: this.space.pointer, metaLocation }, () => {
      this.updateUIBoxes();
    });
  }
}

function zoomElements(e) {
  e.preventDefault();
  // Geom.scale()
  const scaleFactor = 0.1;
  var delta = Math.max(-1, Math.min(1, (e.deltaY || -e.detail)));
  if (delta) {
    var factor = this.state.zoom;
    if (delta > 0) {
      factor += scaleFactor;
    } else {
      factor -= scaleFactor;
    }
    this.setState(
      {
        zoom: factor
      },
      () => {
        this.updateUIBoxes();
      }
    );
  }
  return false;
}
