import React from "react";
// import Footer from "./Footer";
// import Loading from "./Loading";
// import axios from "axios";
import MainCanvas from "./designerMode/MainCanvas";
import UserCanvas from "./designerMode/UserCanvas";

export default class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dmMode: true
    };
  }

  dmModeToggle() {
    this.setState({ dmMode:  !this.state.dmMode });
  }

  render() {
    return (
      <div>
        <div className="dm_header_container">
          <div className="dm_header_logo"></div>
          <div className="dm_header_layoutName">{"Score Update"}</div>
          <div className="dm_header_modeContainer">
            <div className="dm_header_switchContainer">
              <div className="dm_header_switchText">DESIGNER MODE</div>
              <label className="dm_header_switch" htmlFor="checkbox">
                <input
                  checked={this.state.dmMode}
                  type="checkbox"
                  id="checkbox"
                  onChange={e => {
                    this.dmModeToggle();
                  }}
                />
                <div className="dm_header_slider dm_header_round"></div>
              </label>
            </div>
          </div>
        </div>

        {this.state.dmMode ? <MainCanvas dmMode={true} /> : <UserCanvas />}
      </div>
    );
  }
}
