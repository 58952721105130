/*
 * Libraries
 */
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
/*
 * Login
 */
// import "./Login.css";
import Login from "./Login.js";

/*
 * Main App
 */

// import "./Layouts/Layouts.css";
// import "./Components/Components.css";
import Layouts from"./Layouts";
import AddLayout from"./AddLayout";
import Layout from"./Layout";


function App() {
  return (
    <Router>
      <Route exact path="/" component={Login} />
      <Route exact path="/layouts" component={Layouts} />
      <Route exact path="/layouts/add" component={AddLayout} />
      <Route exact path="/layouts/preview/:id" component={Layout} />
    </Router>
  );
}

export default App;
