import React from "react";
import Layers from "./components/Layers";
import Header from "./components/Header";
import EditLayer from "./components/EditLayer";
import "./UserCanvas.css";
import {
  addCanvasEventListeners,
  removeCanvasEventListeners
} from "./api/eventListeners";
import { CanvasSpace, Rectangle, UIDragger, UI, Pt, Line } from "pts";

class MainCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layers: {
        overlay: {
          id: "overlay",
          type: "single",
          name: "Overlay",
          content:
            "https://tig-demo.s3.us-east-1.amazonaws.com/oracle_pro_series/backgrounds/background.png",
          width: 1200,
          height: 650,
          position: [0, 0],
          hidden: false
        },
        pic: {
          id: "pic",
          type: "single",
          name: "Photo Upload",
          content:
            "https://tig-demo.s3.us-east-1.amazonaws.com/oracle_pro_series/backgrounds/player.png",
          width: 877,
          height: 877,
          position: [368, -16],
          hidden: false,
          mask: true
        },
        flagTop: {
          id: "flagTop",
          type: "single",
          name: "Flag Top",
          content:
            "https://tig-demo.s3.us-east-1.amazonaws.com/oracle_pro_series/overlays/flags/usa.png",
          width: 75,
          height: 75,
          position: [206, 476],
          hidden: false
        },
        flagBot: {
          id: "flagBot",
          type: "single",
          name: "Flag Bot",
          content:
            "https://tig-demo.s3.us-east-1.amazonaws.com/oracle_pro_series/overlays/flags/germany.png",
          width: 75,
          height: 75,
          position: [206, 561],
          hidden: false
        },
        lineTop: {
          id: "lineTop",
          name: "Player Name Top",
          type: "textbox",
          content: "(6) J.J. WOLF",
          width: 600,
          height: 60,
          position: [309, 485],
          hidden: false,
          fontSize: 26,
          fontFamily: "AvenirNext-Demi",
          horizontalAlign: "left",
          verticalAlign: "middle"
        },
        lineBot: {
          id: "lineBot",
          name: "Player Name Bot",
          type: "textbox",
          content: "(8) MIKAEL TORPEGAARD",
          width: 600,
          height: 60,
          position: [309, 568],
          hidden: false,
          fontSize: 26,
          fontFamily: "AvenirNext-Demi",
          horizontalAlign: "left",
          verticalAlign: "middle"
        },
        scoreTop1: {
          id: "scoreTop1",
          name: "Score Top 1",
          type: "textbox",
          content: "7",
          width: 100,
          height: 85,
          position: [815, 477],
          hidden: false,
          fontSize: 70,
          fontFamily: "AvenirNext-Bold",
          horizontalAlign: "center",
          verticalAlign: "middle"
        },
        scoreTop2: {
          id: "scoreTop2",
          name: "Score Top 2",
          type: "textbox",
          content: "7",
          width: 100,
          height: 85,
          position: [887, 478],
          hidden: false,
          fontSize: 70,
          fontFamily: "AvenirNext-Bold",
          horizontalAlign: "center",
          verticalAlign: "middle"
        },
        scoreBot1: {
          id: "scoreBot1",
          name: "Score Bot 1",
          type: "textbox",
          content: "6",
          width: 100,
          height: 85,
          position: [815, 548],
          hidden: false,
          fontSize: 70,
          fontFamily: "AvenirNext-Regular",
          horizontalAlign: "center",
          verticalAlign: "middle"
        },
        scoreBot2: {
          id: "scoreBot2",
          name: "Score Bot 2",
          type: "textbox",
          content: "6",
          width: 100,
          height: 85,
          position: [887, 548],
          hidden: false,
          fontSize: 70,
          fontFamily: "AvenirNext-Regular",
          horizontalAlign: "center",
          verticalAlign: "middle"
        }
        // seven: {
        //   id: "seven",
        //   name: "Group Layer",
        //   type: "group",
        //   content: ["eight", "nine"],
        //   width: 50,
        //   height: 50,
        //   position: [894, 150],
        //   hidden: false
        // },
        // eight: {
        //   id: "eight",
        //   type: "single",
        //   name: "Layer single image g1",
        //   content:
        //     "https://tig-demo.s3.us-east-1.amazonaws.com/small_logos_opponent/bandits.png",
        //   width: 512,
        //   height: 288,
        //   position: [120, 120],
        //   group: "seven",
        //   hidden: false
        // },
        // nine: {
        //   id: "nine",
        //   type: "single",
        //   name: "Layer single image g2",
        //   content:
        //     "https://tig-demo.s3.us-east-1.amazonaws.com/small_logos_opponent/fire_dogs.png",
        //   width: 512,
        //   height: 288,
        //   position: [300, 300],
        //   group: "seven",
        //   hidden: false
        // }
      },
      order: {
        pic: 0,
        overlay: 1,
        flagTop: 2,
        flagBot: 3,
        lineTop: 4,
        lineBot: 5,
        scoreTop1: 6,
        scoreTop2: 7,
        scoreBot1: 8,
        scoreBot2: 9
        // five: 4,
        // six: 5
        // seven: 8,
        // eight: 7,
        // nine: 6
      },
      orderArray: [
        "pic",
        "overlay",
        "flagTop",
        "flagBot",
        "lineTop",
        "lineBot",
        "scoreTop1",
        "scoreTop2",
        "scoreBot1",
        "scoreBot2"
        // "five",
        // "six"
        // "nine",
        // "eight",
        // "seven"
      ],
      uiboxes: {},
      uicorners: {},
      images: {},
      corner: -1,
      cursor: "default",
      current: "none",
      focused: "none",
      queue: [],
      dblclickFocused: "none",
      width: 1200,
      height: 650,
      meta: false,
      metaDown: false,
      metaClick: new Pt([0, 0]),
      metaLocation: new Pt([330, 100]),
      zoom: 1
    };
    this.space = {};
    this.addCanvasEventListeners = addCanvasEventListeners.bind(this);
    this.removeCanvasEventListeners = removeCanvasEventListeners.bind(this);
  }

  componentDidMount() {
    this.loadFonts();
    //Initialize variables
    this.space = new CanvasSpace("#mainCanvas");
    let space = this.space;
    space.setup({ bgcolor: "rgba(255,255,255,1)", retina: true, resize: true });
    var form = space.getForm();

    this.addCanvasEventListeners();

    space.add({
      // Only if needed
      start: bound => {
        //bind Double Click
        space.bindCanvas("dblclick", (evt, callback) => {
          space._mouseAction("dblclick", evt);
        });
      },
      resize: (bound, evt) => {},

      animate: (time, ftime) => {
        let layers = this.state.layers;

        this.state.orderArray.forEach(key => {
          if (layers.hasOwnProperty(key)) {
            if (!layers[key].hidden) {
              switch (layers[key].type) {
                case "textbox":
                  this.displayTextbox(form, space, key);
                  break;
                case "single":
                  this.displaySingle(form, space, key);
                  break;
                case "group":
                  this.displayGroup(form, space, key);
                  break;
                default:
                  break;
              }
            }
          }
        });
        this.displayMainCanvas(space);
        this.displaySelectedLayerUI(form);
      },

      action: (type, px, py) => {
        const location = new Pt(px, py);
        if (!this.state.meta) {
          if (this.state.dblclickFocused !== "none") {
            if (
              this.state.uiboxes[this.state.dblclickFocused]._within(
                location
              ) ||
              this.state.corner !== -1
            ) {
              let layers = this.state.layers[this.state.dblclickFocused]
                .content;
              let tracker = [this.state.uiboxes[this.state.dblclickFocused]];
              layers.forEach(key => {
                tracker.push(this.state.uiboxes[key]);
              });
              UI.track(tracker, type, location);
            } else {
              if (type === "down" && this.state.corner === -1) {
                let key = this.state.dblclickFocused;
                let tempOrderArray = Array.from(this.state.orderArray);
                let tempOrder = { ...this.state.order };

                //Update the object Orders
                let numElements = this.state.layers[key].content.length;
                tempOrder[key] = tempOrder[key] + numElements;
                this.state.layers[key].content.forEach(keyLayers => {
                  tempOrder[keyLayers]--; //move one up
                });

                ///Update the array
                tempOrderArray.splice(this.state.order[key], 1); //This removes the element
                tempOrderArray.splice(tempOrder[key], 0, key); //This inserts the key

                this.setState({
                  order: tempOrder,
                  orderArray: tempOrderArray,
                  dblclickFocused: "none",
                  focused: "none"
                });
              }
            }
          } else {
            if (type === "down" && this.state.corner === -1) {
              this.setState({
                focused: "none"
              });
            }

            UI.track(Object.values(this.state.uiboxes), type, location);
          }
          if (this.state.focused !== "none") {
            UI.track(
              Object.values(this.state.uicorners[this.state.focused]),
              type,
              location
            );
          }
        }
      }
    });
    space.bindMouse().play();
  }

  componentWillUnmount() {
    this.removeCanvasEventListeners();
  }

  displaySelectedLayerUI(form) {
    if (
      this.state.current !== "none" &&
      !this.state.layers[this.state.current].hidden
    ) {
      if (this.state.focused !== this.state.current) {
        this.state.uiboxes[this.state.current].render(g => {
          form.fill("rgba(150,150,150,0)");
          form.stroke("rgba(150,150,150,1)", 1.5);
          form.rect(g);
        });
      }
    }
    if (
      this.state.focused !== "none" &&
      !this.state.layers[this.state.focused].hidden
    ) {
      this.state.uiboxes[this.state.focused].render(g => {
        form.dash([5, 5], 5);
        form.fill("rgba(150,150,150,0)");
        form.stroke("rgba(150,150,150,1)", 1.5);
        form.rect(g);
        form.dash(false);
      });
      this.state.uicorners[this.state.focused].map(h => {
        return h.render(g => {
          form.fill("rgba(150,150,150,1)").stroke("rgba(150,150,150,1)");
          form.circle(g);
        });
      });
    }
  }

  displayTextbox(form, space, key) {
    let layer = this.state.layers[key];

    let rect = Rectangle.from(
      this.state.metaLocation.$add(layer.position),
      layer.width,
      layer.height
    );
    if (!this.state.uiboxes.hasOwnProperty(key)) {
      this.initUITextbox(space, key, rect);
    }

    const originalWidth = Rectangle.size(rect)[0];
    //Always make background transparent  **Add it to UI??**
    form.fill("rgba(255,255,255,0)");

    const fontSize = this.state.layers[key].fontSize || 25;
    rect[0].scale(this.state.zoom, this.state.metaLocation);
    rect[1].scale(this.state.zoom, this.state.metaLocation);

    const scaledFont = (fontSize * Rectangle.size(rect)[0]) / originalWidth;
    const fontFamily = this.state.layers[key].fontFamily || "AvenirNext-Demi";
    const horizontalAlign = this.state.layers[key].horizontalAlign || "left";
    const verticalAlign = this.state.layers[key].verticalAlign || "top";
    const color = this.state.layers[key].color || "rgba(0,0,0,1)";

    // Render From State
    form.stroke("rgba(255,255,255,0)", 1);
    form.rect(rect);

    form
      .fontWidthEstimate(false)
      .fillOnly(color)
      .font(scaledFont, "normal", "normal", 1, fontFamily)
      .alignText(horizontalAlign)
      .paragraphBox(rect, layer.content, 1, verticalAlign, false);

    // Render From UI Box
    // this.state.uiboxes[key].render(g => {
    //   form.stroke("rgba(255,255,255,0)", 1);
    //   form.rect(g);
    //   form
    //     .fontWidthEstimate(false)
    //     .fillOnly("rgba(0,0,0,1)")
    //     .font(widthResize(20, Rectangle.size(g)[0]))
    //     .alignText("center")
    //     .paragraphBox(g, layer.content, 1, "middle", false);
    // });
  }

  displaySingle(form, space, key) {
    let layer = this.state.layers[key];

    let rect = Rectangle.from(
      this.state.metaLocation.$add(layer.position),
      layer.width,
      layer.height
    );

    if (!this.state.uiboxes.hasOwnProperty(key)) {
      this.initUISingle(space, key, rect);
    }

    rect[0].scale(this.state.zoom, this.state.metaLocation);
    rect[1].scale(this.state.zoom, this.state.metaLocation);

    //Always make background transparent
    form.fill("rgba(255,255,255,0)");

    // Render From State
    form.stroke("rgba(255,255,255,0)", 1);
    form.rect(rect);
    form.image(this.state.images[key], rect);

    // Render From UI Box
    // this.state.uiboxes[key].render(g => {
    //   form.stroke("rgba(255,255,255,0)", 1);
    //   form.rect(g);
    //   form.image(this.state.images[key], g);
    // });
  }

  initUISingle(space, key, rect) {
    let box = UIDragger.fromRectangle(rect);

    let img = new Image(); // Create new img element
    let images = { ...this.state.images };
    img.src = this.state.layers[key].content;
    images[key] = img;
    this.setState({ images });

    box.onDrag((ui, pt) => {
      // drag handling
      if (this.state.focused === key && !this.state.layers[key].hidden) {
        if (this.state.corner === -1) {
          let width = ui.group[0].$subtract(ui.group[1]).abs();
          ui.group[0].to(space.pointer.$subtract(ui.state("offset")));
          ui.group[1].to(
            space.pointer.$subtract(ui.state("offset")).$add(width)
          );

          if (this.state.layers[key].hasOwnProperty("group")) {
            //Update width
            let boxes = [];
            let groupKey = this.state.layers[key].group;
            let groupLayer = this.state.layers[groupKey];
            groupLayer.content.forEach(key => {
              if (this.state.uiboxes.hasOwnProperty(key)) {
                boxes.push(this.state.uiboxes[key]._group);
              }
            });
            let rect = Rectangle.boundingBox(boxes);
            this.state.uiboxes[groupKey]._group[0].to(rect[0]);
            this.state.uiboxes[groupKey]._group[1].to(rect[1]);

            let uicorner = this.state.uicorners[groupKey];
            let cpoints = Rectangle.corners(rect);
            uicorner.map((h, i) => {
              return (h.group = [cpoints[i], new Pt(4, 4)]);
            });
          }
        }
        let uicorners = this.state.uicorners;
        let cpoints = Rectangle.corners([ui.group[0], ui.group[1]]);
        uicorners[key].map((h, i) => {
          return (h.group = [cpoints[i], new Pt(4, 4)]);
        });
      }
    });

    box.onHover(
      ui => {
        if(!this.state.layers[key].hidden){
          this.uiHoverOn(key);
        }
      },
      ui => {
        if(!this.state.layers[key].hidden){
          this.uiHoverOff(key);
        }
      }
    );

    box.onDrop((ui, pt) => {
      //Updates State Positioning
      this.updateLayerPosition(ui, key);
    });

    box.on("down", (ui, pt) => {
      if (this.state.current === key && this.state.corner === -1  && !this.state.layers[key].hidden) {
        this.setState({
          focused: key
        });
      }
    });

    box.onDrop((ui, pt) => {
      //Updates State Positioning
      this.updateLayerPosition(ui, key);
    });

    let corners = Rectangle.corners(rect).map((h, i) => {
      let ud = UIDragger.fromCircle([h, [4, 4]]);
      ud.onDrag(ui => {
        // drag handling
        let box = this.state.uiboxes[key];
        let cpoints = Rectangle.corners(box._group);
        ui.group[0].to(space.pointer.$subtract(ui.state("offset")));
        // drag handling
        if (i === 0) {
          const point = Line.perpendicularFromPt(
            [cpoints[0], cpoints[2]],
            space.pointer
          );
          box._group[0].to(point);
        } else if (i === 1) {
          const point = Line.perpendicularFromPt(
            [cpoints[1], cpoints[3]],
            space.pointer
          );
          box._group[0].to(box._group[0][0], point[1]);
          box._group[1].to(point[0], box._group[1][1]);
        } else if (i === 2) {
          const point = Line.perpendicularFromPt(
            [cpoints[0], cpoints[2]],
            space.pointer
          );
          box._group[1].to(point);
        } else if (i === 3) {
          const point = Line.perpendicularFromPt(
            [cpoints[1], cpoints[3]],
            space.pointer
          );
          box._group[0].to(point[0], box._group[0][1]);
          box._group[1].to(box._group[1][0], point[1]);
        }
        if (this.state.layers[key].hasOwnProperty("group")) {
          //Update width
          let boxes = [];
          let groupKey = this.state.layers[key].group;
          let groupLayer = this.state.layers[groupKey];
          groupLayer.content.forEach(key => {
            if (this.state.uiboxes.hasOwnProperty(key)) {
              boxes.push(this.state.uiboxes[key]._group);
            }
          });
          let rect = Rectangle.boundingBox(boxes);
          this.state.uiboxes[groupKey]._group[0].to(rect[0]);
          this.state.uiboxes[groupKey]._group[1].to(rect[1]);

          let uicorner = this.state.uicorners[groupKey];
          let cpoints = Rectangle.corners(rect);
          uicorner.map((h, i) => {
            return (h.group = [cpoints[i], new Pt(4, 4)]);
          });
        }
        corners.map((h, i) => {
          return (h.group = [cpoints[i], new Pt(4, 4)]);
        });
      });

      ud.onDrop(ui => {
        this.updateWidthHeightPosition(key, rect);
      });
      ud.onHover(
        // hover handling
        ui => {
          if (i % 2 === 1) {
            return this.setState({ corner: i });
          } else {
            return this.setState({ corner: i });
          }
        },
        ui => {
          return this.setState({ corner: -1 });
        }
      );
      return ud;
    });

    //Box
    var uiboxes = { ...this.state.uiboxes };
    uiboxes[key] = box;

    //Corners
    var uicorners = { ...this.state.uicorners };
    uicorners[key] = corners;

    this.setState({
      uiboxes,
      uicorners
    });
  }

  initUITextbox(space, key, rect) {
    let box = UIDragger.fromRectangle(rect);

    box.onDrag((ui, pt) => {
      // drag handling
      if (this.state.focused === key) {
        if (this.state.corner === -1) {
          const width = ui.group[0].$subtract(ui.group[1]).abs();
          const position = space.pointer.$subtract(ui.state("offset"));
          ui.group[0].to(position);
          ui.group[1].to(position.$add(width));
        }
        let cpoints = Rectangle.corners([ui.group[0], ui.group[1]]);
        this.state.uicorners[key].map((h, i) => {
          return (h.group = [cpoints[i], new Pt(4, 4)]);
        });
      }
    });

    box.onDrop((ui, pt) => {
      this.updateLayerPosition(ui, key);
    });
    box.onHover(
      ui => {
        if(!this.state.layers[key].hidden){
          this.uiHoverOn(key);
        }
      },
      ui => {
        if(!this.state.layers[key].hidden){
          this.uiHoverOff(key);
        }
      }
    );

    box.on("down", (ui, pt) => {
      if (this.state.current === key && this.state.corner === -1 && !this.state.layers[key].hidden) {
        this.setState({
          focused: key
        });
      }
    });

    let corners = this.initUICorners(space, key, rect);

    //Box
    var uiboxes = { ...this.state.uiboxes };
    uiboxes[key] = box;

    //Corners
    var uicorners = { ...this.state.uicorners };
    uicorners[key] = corners;

    this.setState({
      uiboxes,
      uicorners
    });
  }

  /*
   * Methods for Canvas
   */
  displayMainCanvas(space) {
    const ctx = space.ctx;

    ctx.beginPath();

    //outer shape, any direction, this sample is clockwise
    ctx.moveTo(0, 0);
    ctx.lineTo(window.innerWidth, 0);
    ctx.lineTo(window.innerWidth, window.innerHeight);
    ctx.lineTo(0, window.innerHeight);
    ctx.closePath();

    let rect = Rectangle.from(
      [this.state.metaLocation[0], this.state.metaLocation[1]],
      [this.state.width, this.state.height]
    ).scale(this.state.zoom, this.state.metaLocation);
    let scaledRect = Rectangle.corners(rect);

    ctx.moveTo(scaledRect[0][0], scaledRect[0][1]);
    ctx.lineTo(scaledRect[1][0], scaledRect[1][1]);
    ctx.lineTo(scaledRect[2][0], scaledRect[2][1]);
    ctx.lineTo(scaledRect[3][0], scaledRect[3][1]);

    ctx.closePath();
    //fill
    ctx.fillStyle = "rgba(20, 20, 20,1)";
    ctx.mozFillRule = "evenodd"; //for old firefox 1~30
    ctx.strokeStyle = "rgba(20, 20, 20,1)";
    ctx.fill("evenodd");
    ctx.mozFillRule = ""; //for old firefox 1~30
  }
  updateOrder(orderArray) {
    let order = { ...this.state.order };
    let reversedArray = Array.from(orderArray).reverse();
    reversedArray.forEach((value, index) => {
      order[value] = index;
    });

    this.setState({ order, orderArray: reversedArray });
  }

  loadFonts() {
    var junction_font = new FontFace(
      "AvenirNext-Regular",
      "url(https://tig-demo.s3.us-east-1.amazonaws.com/fonts/AvenirNextLTPro-Regular.otf)"
    );
    junction_font
      .load()
      .then(function(loaded_face) {
        document.fonts.add(loaded_face);

        const canvas = document.getElementById("mainCanvas");
        canvas.style.fontFamily = '"AvenirNext-Regular"';
      })
      .catch(function(error) {
        console.log(error);
      });

    var junction_font = new FontFace(
      "AvenirNext-Demi",
      "url(https://tig-demo.s3.us-east-1.amazonaws.com/fonts/AvenirNextLTPro-Demi.otf)"
    );
    junction_font
      .load()
      .then(function(loaded_face) {
        document.fonts.add(loaded_face);

        const canvas = document.getElementById("mainCanvas");
        canvas.style.fontFamily = '"AvenirNext-Demi"';
      })
      .catch(function(error) {
        console.log(error);
      });

    var junction_font = new FontFace(
      "AvenirNext-Bold",
      "url(https://tig-demo.s3.us-east-1.amazonaws.com/fonts/AvenirNextLTPro-Bold.otf)"
    );
    junction_font
      .load()
      .then(function(loaded_face) {
        document.fonts.add(loaded_face);

        const canvas = document.getElementById("mainCanvas");
        canvas.style.fontFamily = '"AvenirNext-Bold"';
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  /*
   * Methods for all Layers
   */

  hideLayer(key) {
    let layers = { ...this.state.layers };
    let hiddenLayer = { ...layers[key] };
    hiddenLayer.hidden = !hiddenLayer.hidden;
    layers[key] = hiddenLayer;

    this.setState({
      layers
    });
  }

  updateFocusedLayer(focused) {
    this.setState({ focused });
  }

  uiHoverOn(key) {
    //Check Prio
    if (this.state.current !== "none") {
      if (this.state.order[key] > this.state.order[this.state.current]) {
        //Check if current has higher prio
        let queue = [this.state.current, ...this.state.queue];
        this.setState({ current: key, queue });
      } else {
        let queue = [key, ...this.state.queue];
        this.setState({ queue });
      }
    } else {
      this.setState({ current: key });
    }
  }

  uiHoverOff(key) {
    //Remove from Queue
    if (this.state.current !== key) {
      let index = -1;
      this.state.queue.forEach((value, i) => {
        if (value === key) {
          index = i;
        }
      });
      let queue = Array.from(this.state.queue);
      queue.splice(index, 1);
      this.setState({
        queue
      });
    } else {
      //Set Next one on queue
      if (this.state.queue.length > 0) {
        let max = -1;
        let nextKey = "";
        let index = -1;
        this.state.queue.forEach((value, i) => {
          if (this.state.order[value] > max) {
            nextKey = value;
            max = this.state.order[value];
            index = i;
          }
        });
        let queue = Array.from(this.state.queue);
        queue.splice(index, 1);
        this.setState({
          queue,
          current: nextKey
        });
      } else {
        this.setState({
          current: "none"
        });
      }
    }
  }

  initUICorners(space, key, rect) {
    return Rectangle.corners(rect).map((h, i) => {
      let ud = UIDragger.fromCircle([h, [4, 4]]);
      ud.onDrag(ui => {
        // drag handling
        let box = this.state.uiboxes[key];
        ui.group[0].to(space.pointer.$subtract(ui.state("offset")));
        if (i === 0) {
          box._group[0].to(space.pointer);
        } else if (i === 1) {
          box._group[0].to(box._group[0][0], space.pointer[1]);
          box._group[1].to(space.pointer[0], box._group[1][1]);
        } else if (i === 2) {
          box._group[1].to(space.pointer);
        } else if (i === 3) {
          box._group[0].to(space.pointer[0], box._group[0][1]);
          box._group[1].to(box._group[1][0], space.pointer[1]);
        }

        let cpoints = Rectangle.corners(box._group);
        this.state.uicorners[key].map((h, i) => {
          return (h.group = [cpoints[i], new Pt(4, 4)]);
        });
      });

      ud.onDrop(ui => {
        this.updateWidthHeightPosition(key);
      });

      ud.onHover(
        // hover handling
        ui => {
          if (i % 2 === 1) {
            return this.setState({ corner: i });
          } else {
            return this.setState({ corner: i });
          }
        },
        ui => {
          return this.setState({ corner: -1 });
        }
      );
      return ud;
    });
  }

  updateLayerPosition(ui, key) {
    if (this.state.focused === key  && !this.state.layers[key].hidden) {
      if (this.state.corner === -1) {
        let position = ui.group[0].$subtract(this.state.metaLocation);
        let layers = { ...this.state.layers };
        let layer = Object.assign({}, layers[key]);
        layer.position = [
          position[0] / this.state.zoom,
          position[1] / this.state.zoom
        ];
        layers[key] = layer;
        this.setState({ layers });
      }
    }
  }

  updateWidthHeightPosition(key) {
    //Update Width, Height & Position
    const size = Rectangle.size(this.state.uiboxes[key]._group);
    const position = this.state.uiboxes[key]._group[0].$subtract(
      this.state.metaLocation
    );
    let layers = { ...this.state.layers };
    let layer = Object.assign({}, layers[key]);
    layer.width = size[0] / this.state.zoom;
    layer.height = size[1] / this.state.zoom;
    layer.position = [
      position[0] / this.state.zoom,
      position[1] / this.state.zoom
    ];
    layers[key] = layer;
    this.setState({ layers });
  }

  updateUIBoxes() {
    Object.keys(this.state.uiboxes).forEach(key => {
      const layer = this.state.layers[key];
      let rect = Rectangle.from(
        this.state.metaLocation.$add(layer.position),
        layer.width,
        layer.height
      );
      rect[0].scale(this.state.zoom, this.state.metaLocation);
      rect[1].scale(this.state.zoom, this.state.metaLocation);
      this.state.uiboxes[key].group = rect;

      let cpoints = Rectangle.corners(rect);
      this.state.uicorners[key].map((h, i) => {
        return (h.group = [cpoints[i], new Pt(4, 4)]);
      });
    });
  }

  updateProperty(property, value, key) {
    let layers = { ...this.state.layers };
    let layer = { ...layers[key] };
    layer[property] = value;
    layers[key] = layer;
    this.setState({
      layers
    });
  }

  /*
   * Add Layers
   */

  addNewTextbox() {
    let layers = Object.assign({}, this.state.layers);
    let order = Object.assign({}, this.state.order);
    let orderArray = Array.from(this.state.orderArray);

    let newId =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
    let newLayer = {
      id: newId,
      name: "New Text Layer",
      type: "textbox",
      content: "New Text Layer",
      width: 200,
      height: 50,
      position: [300, 43],
      hidden: false
    };

    layers[newId] = newLayer;
    order[newId] = orderArray.length;
    orderArray.push(newId);

    const newState = { ...this.state, layers, order, orderArray };
    this.setState(newState);
  }

  deleteTextbox() {}

  /*
   * NEEEDS Fixing
   */

  updateLayerGroupMemberPosition(ui, key) {
    let layers = { ...this.state.layers };
    let layer = Object.assign({}, layers[key]);

    layer.position = [ui._states.offset[0], ui._states.offset[1]];
    layers[key] = layer;
    this.setState({ layers });
  }
  displayGroup(form, space, key) {
    let layer = this.state.layers[key];
    var boxes = [];
    layer.content.forEach(key => {
      if (this.state.uiboxes.hasOwnProperty(key)) {
        boxes.push(this.state.uiboxes[key]._group);
      }
    });

    if (boxes.length) {
      let rect = Rectangle.boundingBox(boxes);
      if (!this.state.uiboxes.hasOwnProperty(key)) {
        this.initUIGroup(space, key, rect);
      }
      //Always make background transparent
      form.fill("rgba(255,255,255,0)");

      //Render From State
      form.stroke("rgba(255,255,255,0)", 1);
      if (this.state.dblclickFocused === key) {
        form.fill("rgba(255,255,255,0.8)");
        form.rect([
          this.state.metaLocation,
          this.state.metaLocation.$add([
            this.state.width + 100,
            this.state.height + 100
          ])
        ]);
        //Fill for inside block
        form.stroke("rgba(150,150,150,1)", 1);
        form.fill("rgba(255,255,255,0)");
      }
      form.rect(rect);

      // Render From UIBox
      // this.state.uiboxes[key].render(g => {
      //   form.stroke("rgba(255,255,255,0)", 1);
      //   if (this.state.dblclickFocused === key) {
      //     form.fill("rgba(255,255,255,0.8)");
      //     form.rect([
      //       this.state.metaLocation,
      //       this.state.metaLocation.$add([
      //         this.state.width + 100,
      //         this.state.height + 100
      //       ])
      //     ]);
      //     //Fill for inside block
      //     form.stroke("rgba(150,150,150,1)", 1);
      //     form.fill("rgba(255,255,255,0)");
      //   }

      //   form.rect(g);
      // });
    }
  }
  initUIGroup(space, key, rect) {
    let box = UIDragger.fromRectangle(rect);
    box.onDrag((ui, pt) => {
      // drag handling
      if (this.state.focused === key) {
        if (this.state.corner === -1) {
          let width = ui.group[0].$subtract(ui.group[1]).abs();
          ui.group[0].to(space.pointer.$subtract(ui.state("offset")));
          ui.group[1].to(
            space.pointer.$subtract(ui.state("offset")).$add(width)
          );
          let memberKeys = this.state.layers[key].content;

          memberKeys.forEach(key => {
            if (this.state.uiboxes.hasOwnProperty(key)) {
              let member = this.state.uiboxes[key];
              let width = member.group[0].$subtract(member.group[1]).abs();
              member.group[0].to(
                space.pointer.$subtract(member.state("offset"))
              );
              member.group[1].to(
                space.pointer.$subtract(member.state("offset")).$add(width)
              );
              if (this.state.uicorners.hasOwnProperty(key)) {
                let uicorner = this.state.uicorners[key];
                let cpoints = Rectangle.corners(member.group);
                uicorner.map((h, i) => {
                  return (h.group = [cpoints[i], new Pt(4, 4)]);
                });
              }
            }
          });
        }

        let uicorners = { ...this.state.uicorners };
        let cpoints = Rectangle.corners(rect);
        uicorners[key].map((h, i) => {
          return (h.group = [cpoints[i], new Pt(4, 4)]);
        });
      }
    });

    box.onDrop(ui => {
      this.updateLayerPosition(ui, key);
      this.state.layers[key].content.forEach(elements => {
        this.updateLayerGroupMemberPosition(ui, elements);
      });
    });

    box.onHover(
      ui => {
        this.uiHoverOn(key);
      },
      ui => {
        this.uiHoverOff(key);
      }
    );

    box.on("down", (ui, pt) => {
      if (this.state.current === key && this.state.corner === -1) {
        this.setState({
          focused: key
        });
      }

      let memberKeys = this.state.layers[key].content;
      memberKeys.forEach(key => {
        if (this.state.uiboxes.hasOwnProperty(key)) {
          let member = this.state.uiboxes[key];
          member.state("offset", space.pointer.$subtract(member.group[0]));
        }
      });
    });
    box.on("up", (ui, pt) => {
      let memberKeys = this.state.layers[key].content;
      memberKeys.forEach(key => {
        if (this.state.uiboxes.hasOwnProperty(key)) {
          let member = this.state.uiboxes[key];
          member.state("offset", [0, 0]);
        }
      });
    });

    box.on("dblclick", (ui, pt) => {
      //Temporarily Change order
      if (this.state.dblclickFocused !== key) {
        var tempOrderArray = Array.from(this.state.orderArray);
        var tempOrder = { ...this.state.order };

        //Update the object Orders
        var numElements = this.state.layers[key].content.length;
        tempOrder[key] = tempOrder[key] - numElements;
        this.state.layers[key].content.forEach(keyLayers => {
          tempOrder[keyLayers]++; //move one up
        });

        ///Update the array
        tempOrderArray.splice(this.state.order[key], 1); //This removes the element
        tempOrderArray.splice(tempOrder[key], 0, key); //This inserts the key

        if (this.state.queue.length > 0) {
          let max = -1;
          let nextKey = "";
          let index = -1;
          this.state.queue.forEach((value, i) => {
            if (this.state.order[value] > max) {
              nextKey = value;
              max = this.state.order[value];
              index = i;
            }
          });
          let queue = Array.from(this.state.queue);
          queue.splice(index, 1);
          this.setState({
            queue,
            current: nextKey
          });
        }

        this.setState({
          order: tempOrder,
          orderArray: tempOrderArray,
          dblclickFocused: key
        });
      }
    });

    let corners = Rectangle.corners(rect).map((h, i) => {
      let ud = UIDragger.fromCircle([h, [4, 4]]);
      ud.onDrag(ui => {
        // drag handling
        let box = this.state.uiboxes[key];
        let cpoints = Rectangle.corners(rect);
        ui.group[0].to(space.pointer.$subtract(ui.state("offset")));

        // drag handling
        if (i === 0) {
          const point = Line.perpendicularFromPt(
            [cpoints[0], cpoints[2]],
            space.pointer
          );
          box._group[0].to(point);

          let scale =
            Rectangle.size([box._group[0], box._group[1]])[0] /
            Rectangle.size([cpoints[0], cpoints[2]])[0];
          let memberKeys = this.state.layers[key].content;
          memberKeys.forEach(key => {
            if (this.state.uiboxes.hasOwnProperty(key)) {
              let member = this.state.uiboxes[key];
              member.group.scale(scale, cpoints[2]);
            }
          });
        } else if (i === 1) {
          const point = Line.perpendicularFromPt(
            [cpoints[1], cpoints[3]],
            space.pointer
          );

          box._group[0].to(box._group[0][0], point[1]);
          box._group[1].to(point[0], box._group[1][1]);
          let scale =
            Rectangle.size([box._group[0], box._group[1]])[0] /
            Rectangle.size([cpoints[0], cpoints[2]])[0];
          let memberKeys = this.state.layers[key].content;
          memberKeys.forEach(key => {
            if (this.state.uiboxes.hasOwnProperty(key)) {
              let member = this.state.uiboxes[key];
              member.group.scale(scale, cpoints[3]);
            }
          });
        } else if (i === 2) {
          const point = Line.perpendicularFromPt(
            [cpoints[0], cpoints[2]],
            space.pointer
          );
          box._group[1].to(point);

          let scale =
            Rectangle.size([box._group[0], box._group[1]])[0] /
            Rectangle.size([cpoints[0], cpoints[2]])[0];
          let memberKeys = this.state.layers[key].content;
          memberKeys.forEach(key => {
            if (this.state.uiboxes.hasOwnProperty(key)) {
              let member = this.state.uiboxes[key];
              member.group.scale(scale, cpoints[0]);
            }
          });
        } else if (i === 3) {
          const point = Line.perpendicularFromPt(
            [cpoints[1], cpoints[3]],
            space.pointer
          );
          box._group[0].to(point[0], box._group[0][1]);
          box._group[1].to(box._group[1][0], point[1]);

          let scale =
            Rectangle.size([box._group[0], box._group[1]])[0] /
            Rectangle.size([cpoints[0], cpoints[2]])[0];
          let memberKeys = this.state.layers[key].content;
          memberKeys.forEach(key => {
            if (this.state.uiboxes.hasOwnProperty(key)) {
              let member = this.state.uiboxes[key];
              member.group.scale(scale, cpoints[1]);
            }
          });
        }
        corners.map((h, i) => {
          return (h.group = [cpoints[i], new Pt(4, 4)]);
        });
      });

      ud.onHover(
        // hover handling
        ui => {
          if (i % 2 === 1) {
            return this.setState({ corner: i });
          } else {
            return this.setState({ corner: i });
          }
        },
        ui => {
          return this.setState({ corner: -1 });
        }
      );
      return ud;
    });

    //Box
    var uiboxes = { ...this.state.uiboxes };
    uiboxes[key] = box;

    //Corners
    var uicorners = { ...this.state.uicorners };
    uicorners[key] = corners;

    this.setState({
      uiboxes,
      uicorners
    });
  }

  render() {
    return (
      <div className="dmContainer">
        <div
          style={{
            width: "100%",
            height: "100vh",
            cursor: this.state.cursor
          }}
        >
          <canvas id="mainCanvas" />
        </div>
        {this.props.dmMode ? (
          <Layers
            layers={this.state.layers}
            order={this.state.orderArray}
            title="Layers"
            updateOrder={this.updateOrder.bind(this)}
            newTextbox={this.addNewTextbox.bind(this)}
            hideLayer={this.hideLayer.bind(this)}
            selectedLayer={this.state.focused}
            updateFocusedLayer={this.updateFocusedLayer.bind(this)}
          />
        ) : (
          ""
        )}
        {this.state.focused !== "none" && this.props.dmMode ? (
          <EditLayer
            layer={this.state.layers[this.state.focused]}
            layerKey={this.state.focused}
            updateProperty={this.updateProperty.bind(this)}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MainCanvas;
