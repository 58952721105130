import React from "react";
import "./Header.css";

class RemoveLayer extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {};
  // }

  render() {
    return (
      <div className="dm_header_container">
        <div className="dm_header_logo"></div>
        <div className="dm_header_layoutName">{"Layout 1"}</div>
        <div className="dm_header_modeContainer">
          <div className="dm_header_switchContainer">
            <div className="dm_header_switchText">DESIGNER MODE</div>
            <label className="dm_header_switch" htmlFor="checkbox">
              <input checked={this.props.dmMode} type="checkbox" id="checkbox" onChange={(e)=>{this.props.dmModeToggle()}} />
              <div className="dm_header_slider dm_header_round"></div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default RemoveLayer;
