import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Layers.css";
import "./AddLayer.css";
import AddLayer from "./AddLayer";
import RemoveLayer from "./RemoveLayer";

class Layers extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  onDragStart = start => {
    console.log(start);
  };

  onDragUpdate = update => {};

  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newTasksIds = Array.from(this.props.order).reverse();
    newTasksIds.splice(source.index, 1);
    newTasksIds.splice(destination.index, 0, draggableId);

    this.props.updateOrder(newTasksIds);
  };

  render() {
    //Fix for correct display
    const layerIds = Array.from(this.props.order).reverse();
    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.onDragEnd}
      >
        <div className="dm_layers_container">
          <div className="dm_layers_title">Layers</div>
          <div className="dm_layers_icons">
            <AddLayer newTextbox={this.props.newTextbox} />
            <RemoveLayer />
          </div>
          <Droppable droppableId={"LayerID"}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="dm_layers_list"
              >
                {layerIds.map((layerId, index) => {
                  let layer = this.props.layers[layerId];
                  return (
                    <Draggable
                      draggableId={layerId}
                      index={index}
                      key={layerId}
                      disableInteractiveElementBlocking={true}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          group={layer.group}
                          onClick={e => {
                            this.props.updateFocusedLayer(layer.id);
                          }}
                          className={
                            "dm_layers_item" +
                            (layer.group ? " dm_layers_groupItem" : "")
                          }
                        >
                          <label
                            htmlFor={"checkbox" + layer.id}
                            className="dm_layers_checkboxButton"
                          >
                            <input
                              checked={!layer.hidden}
                              className={"dm_layers_checkboxButtonInput"}
                              type="checkbox"
                              name={"checkbox" + layer.id}
                              id={"checkbox" + layer.id}
                              onChange={e => {
                                this.props.hideLayer(layer.id);
                              }}
                            />
                            <span
                              className={"dm_layers_checkboxButtonControl"}
                            ></span>
                            <input
                              type="textbox"
                              className={
                                "dm_layers_checkboxButtonLabel" +
                                (layer.group
                                  ? " dm_layers_labelGroupItem"
                                  : "") +
                                (this.props.selectedLayer === layer.id
                                  ? " dm_layers_selectedLayer"
                                  : "")
                              }
                              value={layer.name || layer.id}
                              readOnly
                            />
                          </label>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    );
  }
}

export default Layers;
