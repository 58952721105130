import React from "react";
import Portal from "../../sharedComponents/Portal";
import "./AddLayer.css";

class AddLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      newLayerName: ""
    };
  }
  render() {
    return (
      <div>
        <Portal>
          {this.state.displayMenu ? (
            <div className="dm_addLayer_lightbox">
              <div className="dm_addLayer_box">
                <div
                  className="dm_addLayer_closeBox"
                  onClick={e => {
                    this.setState({ displayMenu: !this.state.displayMenu });
                  }}
                >
                  x
                </div>
                <div className="dm_addLayer_boxTitle"> Add New Layer</div>
                <div className="dm_addLayer_layerTypesContainer">
                  <div className="dm_addLayer_optionContainer" onClick={e=>{this.props.newTextbox(); this.setState({displayMenu: false})}}>
                    <div className="dm_addLayer_icons dm_addLayer_textIcon" />
                    <div className="dm_addLayer_layerTypeTitle">Text</div>
                    <div className="dm_addLayer_layerInfo">
                      Description
                    </div>
                  </div>
                  <div className="dm_addLayer_optionContainer">
                    <div className="dm_addLayer_icons dm_addLayer_imageIcon" />
                    <div className="dm_addLayer_layerTypeTitle">Image</div>
                    <div className="dm_addLayer_layerInfo">
                      Description
                    </div>
                  </div>
                  <div className="dm_addLayer_optionContainer">
                    <div className="dm_addLayer_icons dm_addLayer_imageGroupIcon" />
                    <div className="dm_addLayer_layerTypeTitle">
                      Image Group
                    </div>
                    <div className="dm_addLayer_layerInfo">
                      Description
                    </div>
                  </div>
                  <div className="dm_addLayer_optionContainer">
                    <div className="dm_addLayer_icons dm_addLayer_photoUploadIcon" />
                    <div className="dm_addLayer_layerTypeTitle">
                      Photo Upload
                    </div>
                    <div className="dm_addLayer_layerInfo">
                      Description
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Portal>
        <div
          className="dm_addLayer_layersAddIcon"
          onClick={e => {
            this.setState({ displayMenu: !this.state.displayMenu });
          }}
        >
          +
          <div className="dm_addLayer_layersAddIcon_tooltiptext">
            {" "}
            Add Layer
          </div>
        </div>
      </div>
    );
  }
}

export default AddLayer;
