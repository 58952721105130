import React from "react";
import Header from "./components/Header";
import "./MainCanvas.css";
import { CanvasSpace, Rectangle, Pt } from "pts";

class MainCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layers: {
        overlay: {
          id: "overlay",
          type: "single",
          name: "Overlay",
          content:
            "https://tig-demo.s3.us-east-1.amazonaws.com/oracle_pro_series/backgrounds/background.png",
          width: 1201,
          height: 651,
          position: [-1, -1],
          hidden: false
        },
        pic: {
          id: "pic",
          type: "single",
          name: "Photo Upload",
          content:
            "https://tig-demo.s3.us-east-1.amazonaws.com/oracle_pro_series/backgrounds/player.png",
          width: 877,
          height: 877,
          position: [368, -16],
          hidden: false
        },
        flagTop: {
          id: "flagTop",
          type: "single",
          name: "Flag Top",
          content:
            "https://tig-demo.s3.us-east-1.amazonaws.com/oracle_pro_series/overlays/flags/usa.png",
          width: 75,
          height: 75,
          position: [206, 476],
          hidden: false
        },
        flagBot: {
          id: "flagBot",
          type: "single",
          name: "Flag Bot",
          content:
            "https://tig-demo.s3.us-east-1.amazonaws.com/oracle_pro_series/overlays/flags/germany.png",
          width: 75,
          height: 75,
          position: [206, 561],
          hidden: false
        },
        lineTop: {
          id: "lineTop",
          name: "Player Name Top",
          type: "textbox",
          content: "(6) J.J. WOLF",
          width: 600,
          height: 60,
          position: [309, 485],
          hidden: false
        },
        lineBot: {
          id: "lineBot",
          name: "Player Name Bot",
          type: "textbox",
          content: "(8) MIKAEL TORPEGAARD",
          width: 600,
          height: 60,
          position: [309, 568],
          hidden: false
        },
        scoreTop1: {
          id: "scoreTop1",
          name: "Score Top 1",
          type: "textbox",
          content: "7",
          width: 100,
          height: 85,
          position: [815, 477],
          hidden: false,
          fontSize: 70,
          fontFamily: "AvenirNext-Bold",
          align: "center"
        },
        scoreTop2: {
          id: "scoreTop2",
          name: "Score Top 2",
          type: "textbox",
          content: "7",
          width: 100,
          height: 85,
          position: [887, 478],
          hidden: false,
          fontSize: 70,
          fontFamily: "AvenirNext-Bold",
          align: "center"
        },
        scoreBot1: {
          id: "scoreBot1",
          name: "Score Bot 1",
          type: "textbox",
          content: "6",
          width: 100,
          height: 85,
          position: [815, 548],
          hidden: false,
          fontSize: 70,
          fontFamily: "AvenirNext-Regular",
          align: "center"
        },
        scoreBot2: {
          id: "scoreBot2",
          name: "Score Bot 2",
          type: "textbox",
          content: "6",
          width: 100,
          height: 85,
          position: [887, 548],
          hidden: false,
          fontSize: 70,
          fontFamily: "AvenirNext-Regular",
          align: "center"
        }
        // seven: {
        //   id: "seven",
        //   name: "Group Layer",
        //   type: "group",
        //   content: ["eight", "nine"],
        //   width: 50,
        //   height: 50,
        //   position: [894, 150],
        //   hidden: false
        // },
        // eight: {
        //   id: "eight",
        //   type: "single",
        //   name: "Layer single image g1",
        //   content:
        //     "https://tig-demo.s3.us-east-1.amazonaws.com/small_logos_opponent/bandits.png",
        //   width: 512,
        //   height: 288,
        //   position: [120, 120],
        //   group: "seven",
        //   hidden: false
        // },
        // nine: {
        //   id: "nine",
        //   type: "single",
        //   name: "Layer single image g2",
        //   content:
        //     "https://tig-demo.s3.us-east-1.amazonaws.com/small_logos_opponent/fire_dogs.png",
        //   width: 512,
        //   height: 288,
        //   position: [300, 300],
        //   group: "seven",
        //   hidden: false
        // }
      },
      order: {
        pic: 1, 
        overlay: 0,
        flagTop: 2,
        flagBot: 3,
        lineTop: 4,
        lineBot: 5,
        scoreTop1: 6,
        scoreTop2: 7,
        scoreBot1: 8,
        scoreBot2: 9
        // five: 4,
        // six: 5
        // seven: 8,
        // eight: 7,
        // nine: 6
      },
      orderArray: [
        "pic",
        "overlay",
        "flagTop",
        "flagBot",
        "lineTop",
        "lineBot",
        "scoreTop1",
        "scoreTop2",
        "scoreBot1",
        "scoreBot2"
        // "five",
        // "six"
        // "nine",
        // "eight",
        // "seven"
      ],
      images: {},
      corner: -1,
      cursor: "default",
      current: "none",
      focused: "none",
      queue: [],
      dblclickFocused: "none",
      width: 1200,
      height: 650,
      meta: false,
      metaDown: false,
      metaClick: new Pt([0, 0]),
      metaLocation: new Pt([0, 0]),
      layoutName: "Layout 1",
      tigWidth: 1200,
      containerHeight: 0,
      containerWidth: 0,
      scale: 1
    };
    this.space = {};

    this.updateDim = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    var scale =
      (window.innerWidth - 60) /
      document.getElementById("tig_main").clientWidth;
    if (scale > 1) {
      scale = 1;
    }
    this.setState({
      scale,
      containerWidth: window.innerWidth - 60,
      containerHeight: Math.floor(
        document.getElementById("tig_main").clientHeight * scale
      )
    });
  }

  componentDidMount() {
    this.loadFonts();
    //Initialize variables
    this.space = new CanvasSpace("#userCanvas");
    let space = this.space;
    space.setup({
      bgcolor: "rgba(255,255,255,1)",
      retina: true,
      resize: false
    });
    var form = space.getForm();

    space.add({
      // Only if needed
      start: bound => {
        this.updateDim();
        window.addEventListener("resize", this.updateDim );
      },
      resize: (bound, evt) => {},

      animate: (time, ftime) => {
        let layers = this.state.layers;

        this.displayMainCanvas(form);
        this.state.orderArray.forEach(key => {
          if (layers.hasOwnProperty(key)) {
            if (!layers[key].hidden) {
              switch (layers[key].type) {
                case "textbox":
                  this.displayTextbox(form, key);
                  break;
                case "single":
                  this.displaySingle(form, key);
                  break;
                case "group":
                  this.displayGroup(form, key);
                  break;
                default:
                  break;
              }
            }
          }
        });
      },

      action: (type, px, py) => {}
    });
    space.bindMouse().play();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDim);
  }

  displayTextbox(form, key) {
    let layer = this.state.layers[key];

    let rect = Rectangle.from(layer.position, layer.width, layer.height);

    //Always make background transparent  **Add it to UI??**
    form.fill("rgba(255,255,255,0)");
    const fontSize = this.state.layers[key].fontSize || 25;
    const fontFamily = this.state.layers[key].fontFamily || "AvenirNext-Demi";
    const align = this.state.layers[key].align || "left";
    const color = this.state.layers[key].color || "rgba(0,0,0,1)";
    // Render From State
    form.stroke("rgba(255,255,255,0)", 1);
    form.rect(rect);
    form
      .fontWidthEstimate(false)
      .fillOnly(color)
      .font(fontSize, "normal", "normal", 1, fontFamily)
      .alignText(align)
      .paragraphBox(rect, layer.content, 1, "center", false);
  }

  displaySingle(form, key) {
    let layer = this.state.layers[key];

    if (!this.state.images[key]) {
      let img = new Image(); // Create new img element
      img.crossOrigin = "Anonymous";
      img.src = this.state.layers[key].content;
      
      let images = { ...this.state.images };
      images[key] = img;
      this.setState({ images });
    }

    let rect = Rectangle.from(layer.position, layer.width, layer.height);

    //Always make background transparent
    form.fill("rgba(255,255,255,0)");

    // Render From State
    form.stroke("rgba(255,255,255,0)", 1);
    form.rect(rect);
    form.image(this.state.images[key], rect);
  }

  /*
   * Methods for Canvas
   */
  displayMainCanvas(form) {
    let rect = Rectangle.from([0, 0], [this.state.width, this.state.height]);

    form.fill("rgba(255,255,255,1)");
    form.rect(rect);
  }

  dmModeToggle() {
    this.setState({ dmMode: !this.state.dmMode });
  }

  loadFonts() {
    var junction_font = new FontFace('AvenirNext-Regular', 'url(https://tig-demo.s3.us-east-1.amazonaws.com/fonts/AvenirNextLTPro-Regular.otf)');
    junction_font.load().then(function(loaded_face) {
      document.fonts.add(loaded_face);

      const canvas = document.getElementById("userCanvas")
      canvas.style.fontFamily = '"AvenirNext-Regular"';
    }).catch(function(error) {
      console.log(error);
    });

    var junction_font = new FontFace('AvenirNext-Demi', 'url(https://tig-demo.s3.us-east-1.amazonaws.com/fonts/AvenirNextLTPro-Demi.otf)');
    junction_font.load().then(function(loaded_face) {
      document.fonts.add(loaded_face);

      const canvas = document.getElementById("userCanvas")
      canvas.style.fontFamily = '"AvenirNext-Demi"';
    }).catch(function(error) {
      console.log(error);
    });

    var junction_font = new FontFace('AvenirNext-Bold', 'url(https://tig-demo.s3.us-east-1.amazonaws.com/fonts/AvenirNextLTPro-Bold.otf)');
    junction_font.load().then(function(loaded_face) {
      document.fonts.add(loaded_face);

      const canvas = document.getElementById("userCanvas")
      canvas.style.fontFamily = '"AvenirNext-Bold"';
    }).catch(function(error) {
      console.log(error);
    });
  }
  
  export(){

    var canvas = document.getElementById('userCanvas');
    var data = canvas.toDataURL('image/png',1);
    // var resizedCanvas = document.createElement("canvas");
    // var resizedContext = resizedCanvas.getContext("2d");

    // resizedCanvas.height = this.state.height;
    // resizedCanvas.width = this.state.width;

    // var canvas = document.getElementById("userCanvas");

    // resizedContext.drawImage(canvas, 0, 0, this.state.width, this.state.height);
    // var myResizedData = resizedCanvas.toDataURL('image/png',1);


    var link = document.createElement('a');
    link.download = this.state.layoutName.replace(' ', '_')+'_'+Math.floor(Date.now() / 1000)+'.png';
    link.href = data;
    link.click();
  }

  render() {
    return (
      <div className="dmContainer">
        <div
          id="tig_container"
          className="tig_container"
          style={{
            width: this.state.containerWidth,
            height: this.state.containerHeight,
            overflow: "visible"
          }}
        >
          <div
            id="tig_main"
            className="tig_main"
            style={{
              width: this.state.width,
              height: this.state.height,
              transform:
                "translate(-50%, -50%) scale(" + this.state.scale + ")",
              display: "inline-block"
            }}
          >
            <canvas
              id="userCanvas"
              width={this.state.width} 
              height={this.state.height}
              style={{ width: this.state.width, height: this.state.height }}
            />
          </div>
        </div>
        <div className="um_exportButton_container" style={{width: "300px", margin:"auto"}}>
          <div className="pure-material-button-outlined" onClick={(e)=>{
            this.export()
          }} >
            Export
          </div>
        </div>
      </div>
    );
  }
}

export default MainCanvas;
