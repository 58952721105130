import React from 'react';
import loadingIcon from "./img/loading.svg";

function Loading(){
  return (
    <div className="loader loader--style1" title="0">
      <img src={loadingIcon} alt="loading" />
    </div>
  );
};


export default Loading;