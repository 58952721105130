import React from "react";
import Footer from "./Footer";
import Loading from "./Loading";
import axios from "axios";

export default class Layouts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layouts: [],
      loading: true,
      message: ""
    };
  }

  componentDidMount() {
    axios
      .get(process.env.REACT_APP_TIGS_API_BASE_URL + "listAll", {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.userInfo).token //the token is a variable which holds the token
        }
      })
      .then(response => {
        if(response.data.error){
          this.setState({ message: response.data.error });
        }else{
          let layouts = Array.from(response.data.layouts);
          this.setState({ layouts: layouts });
        }
      })
      .catch(error => {
        if (error.response.data.message) {
          this.setState({ message: error.response.data.message });
        }
      });
  }

  render() {
    return (
      <div className="mainContainer">
        <div className="topContainer"></div>
        <div className="teamInfoLogoLong"></div>
        <div style={{ flex: 1 }}>
          <div className="layouts_container">
            {this.state.message}
            {this.state.layouts.map((layout, index) => {
              return (
                <div
                  key={index + layout._id}
                  className="layouts_box"
                  onClick={() => {
                    this.props.history.push("/layouts/preview/" + layout._id);
                  }}
                >
                  <div
                    className="layouts_box_bg"
                    style={{
                      backgroundImage: "url(" + layout.bgPreview + ")"
                    }}
                  />
                  <div className="layouts_box_text">{layout.name}</div>
                </div>
              );
            })}

            {!this.state.loading ? <Loading /> : ""}
            <span />
            <div
              className="layouts_box_add"
              onClick={e => {
                e.preventDefault();
                this.props.history.push("/layouts/add");
              }}
            >
              +<span className="tooltiptext">Add New Layout</span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
