import React from "react";
import "./Layers.css";
import "./EditLayer.css";
import { SketchPicker } from "react-color";

class EditLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorClicked: false,
      color: "#000",
      size: 10,
      fontSize: 10,
      dragging: false
    };
    var dragCount = 0;
    this.dragCount = dragCount;
  }

  handleChangeComplete(color) {
    this.setState(
      {
        color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a} )`
      },
      e => {
        this.props.updateProperty(
          "color",
          `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a} )`,
          this.props.layerKey
        );
      }
    );
  }

  componentDidMount(){
    var div = document.getElementById("droppable");
    div.addEventListener("dragenter", this.dragenterDragleave.bind(this));
    div.addEventListener("dragleave", this.dragenterDragleave.bind(this));
    div.addEventListener('dragexit', this.handleDragExit)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }

  componentWillUnmount(){
    var div = document.getElementById("droppable");
    div.removeEventListener("dragenter", this.dragenterDragleave.bind(this));
    div.removeEventListener("dragleave", this.dragenterDragleave.bind(this));
    div.removeEventListener('dragexit', this.handleDragExit)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragExit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({dragging:false})
  }
  handleDrop = (e) => {    
    e.preventDefault()
    e.stopPropagation()
    this.setState({dragging:false})
  }

  dragenterDragleave(e) {
    e.preventDefault()
    e.stopPropagation()
    console.log(e);
    this.dragCount += (e.type === "dragenter" ? 1 : -1);
    if (this.dragCount === 1) {
      this.setState({dragging:true});
    } else if (this.dragCount === 0) {
      this.setState({dragging:false});
    }
  };

  handleKeyDown(e) {
    if (e.keyCode === 38) {
      e.preventDefault();
      this.setState({ fontSize: this.state.fontSize + 1 });
    } else if (e.keyCode === 40) {
      e.preventDefault();
      this.setState({ fontSize: this.state.fontSize - 1 });
    }
  }

  setAlignHorizontal(value) {
    this.props.updateProperty("horizontalAlign", value, this.props.layerKey);
  }

  setAlignVertical(value) {
    this.props.updateProperty("verticalAlign", value, this.props.layerKey);
  }

  render() {
    return (
      <div className="dm_editLayer_container" id="droppable">
        <div>
          {this.props.layer.type === "textbox" ? (
            <div>
              <div className="dm_editLayer_title"> Box Properties </div>
              <div className="dm_editorLayer_textboxPropertiesContainer">
                <div className="dm_editLayer_textboxContainer">
                  <label className="pure-material-textfield-outlined addPxSuffixToInput">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={Math.trunc(this.props.layer.width)}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.props.updateProperty(
                          "width",
                          parseInt(e.target.value),
                          this.props.layerKey
                        );
                      }}
                    />
                    <span>Width</span>
                  </label>
                </div>
                <div className="dm_editLayer_textboxContainer">
                  <label className="pure-material-textfield-outlined addPxSuffixToInput">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={Math.trunc(this.props.layer.height)}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.props.updateProperty(
                          "height",
                          parseInt(e.target.value),
                          this.props.layerKey
                        );
                      }}
                    />
                    <span>Height</span>
                  </label>
                </div>
              </div>
              <div className="dm_editorLayer_textboxPropertiesContainer">
                <div className="dm_editLayer_textboxContainer">
                  <label className="pure-material-textfield-outlined addPxSuffixToInput">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={Math.trunc(this.props.layer.position[0])}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.props.updateProperty(
                          "position",
                          [
                            parseInt(e.target.value),
                            this.props.layer.position[1]
                          ],
                          this.props.layerKey
                        );
                      }}
                    />
                    <span>X</span>
                  </label>
                </div>
                <div className="dm_editLayer_textboxContainer">
                  <label className="pure-material-textfield-outlined addPxSuffixToInput">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={Math.trunc(this.props.layer.position[1])}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.props.updateProperty(
                          "position",
                          [
                            this.props.layer.position[0],
                            parseInt(e.target.value)
                          ],
                          this.props.layerKey
                        );
                      }}
                    />
                    <span>Y</span>
                  </label>
                </div>
              </div>

              <div>
                {
                  this.state.dragging?
                    <div className="droppableBox">
                      Drop File Here
                    </div>
                  :
                  ""
                }
                <div className="dm_editLayer_title"> Text Properties </div>

                <div
                  className="dm_editorLayer_textboxPropertiesContainer"
                  style={{ padding: "10px 40px" }}
                >
                  <label className="pure-material-textfield-outlined">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={this.props.layer.content}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.props.updateProperty(
                          "content",
                          e.target.value,
                          this.props.layerKey
                        );
                      }}
                    />
                    <span>Content</span>
                  </label>
                </div>
                <div className="dm_editorLayer_textboxPropertiesContainer">
                  <div className="dm_editLayer_dropdownContainer">
                    <div className="select">
                      <select
                        className="select-text"
                        value={this.props.layer.fontFamily}
                        onChange={e => {
                          this.props.updateProperty(
                            "fontFamily",
                            e.target.value,
                            this.props.layerKey
                          );
                        }}
                      >
                        <option value="AvenirNext-Regular">
                          AvenirNext-Regular
                        </option>
                        <option value="AvenirNext-Demi">AvenirNext-Demi</option>
                        <option value="AvenirNext-Bold">AvenirNext-Bold</option>
                      </select>
                      <label className="select-label">Font Family</label>
                    </div>
                    <input
                      type="file"
                      id="imgupload"
                      onChange={e => {
                        console.log(e);
                      }}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="imgupload"
                      className="dm_editLayerUploadFont"
                    >
                      <span className="tooltiptext">Upload Custom Font</span>
                    </label>
                  </div>
                </div>
                <div className="dm_editorLayer_textboxPropertiesContainer">
                  <div className="dm_editLayer_textboxContainer">
                    <label className="pure-material-textfield-outlined addPxSuffixToInput">
                      <input
                        placeholder=" "
                        type="Textbox"
                        value={this.props.layer.fontSize}
                        onChange={e => {
                          this.props.updateProperty(
                            "fontSize",
                            e.target.value,
                            this.props.layerKey
                          );
                        }}
                      />
                      <span>Font Size</span>
                    </label>
                  </div>

                  <div className="dm_editLayer_colorContainer">
                    <div className="dm_editLayer_colorTitle">Color</div>
                    <div
                      className="dm_editLayer_colorBox"
                      onClick={e => {
                        this.setState({
                          colorClicked: !this.state.colorClicked
                        });
                      }}
                    >
                      <div
                        style={{
                          backgroundImage:
                            "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==')",
                          width: "50px",
                          height: "20px",
                          position: "absolute"
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: this.state.color,
                          width: "50px",
                          height: "20px",
                          position: "absolute"
                        }}
                      />
                    </div>
                  </div>
                </div>
                {this.state.colorClicked ? (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 5,
                      margin: "-10px 0px 0px 40px"
                    }}
                  >
                    <SketchPicker
                      color={this.state.color}
                      onChangeComplete={this.handleChangeComplete.bind(this)}
                      presetColors={[]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                <div className="dm_editLayer_title"> Alignment </div>
                <div
                  className="dm_editorLayer_textboxPropertiesContainer"
                  onChange={e => {
                    this.setAlignHorizontal(e.target.value);
                  }}
                >
                  <input
                    id="a1"
                    type="radio"
                    name="a"
                    value="left"
                    className="dm_editorLayerHideInput"
                    defaultChecked={this.props.layer.horizontalAlign === "left"}
                  />
                  <label
                    htmlFor="a1"
                    className="dm_editorLayer_textAlignLeft"
                  ></label>
                  <input
                    id="a2"
                    type="radio"
                    name="a"
                    value="center"
                    className="dm_editorLayerHideInput"
                    defaultChecked={
                      this.props.layer.horizontalAlign === "center"
                    }
                  />
                  <label
                    htmlFor="a2"
                    className="dm_editorLayer_textAlignCenter"
                  ></label>
                  <input
                    id="a3"
                    type="radio"
                    name="a"
                    value="right"
                    className="dm_editorLayerHideInput"
                    defaultChecked={
                      this.props.layer.horizontalAlign === "right"
                    }
                  />
                  <label
                    htmlFor="a3"
                    className="dm_editorLayer_textAlignRight"
                  ></label>
                </div>
                <div
                  className="dm_editorLayer_textboxPropertiesContainer"
                  onChange={e => {
                    this.setAlignVertical(e.target.value);
                  }}
                >
                  <input
                    id="b1"
                    type="radio"
                    name="b"
                    value="top"
                    className="dm_editorLayerHideInput"
                    defaultChecked={this.props.layer.verticalAlign === "top"}
                  />
                  <label
                    htmlFor="b1"
                    className="dm_editorLayer_textAlignTop"
                  ></label>
                  <input
                    id="b2"
                    type="radio"
                    name="b"
                    value="middle"
                    className="dm_editorLayerHideInput"
                    defaultChecked={this.props.layer.verticalAlign === "middle"}
                  />
                  <label
                    htmlFor="b2"
                    className="dm_editorLayer_textAlignMiddle"
                  ></label>
                  <input
                    id="b3"
                    type="radio"
                    name="b"
                    value="bottom"
                    className="dm_editorLayerHideInput"
                    defaultChecked={this.props.layer.verticalAlign === "bottom"}
                  />
                  <label
                    htmlFor="b3"
                    className="dm_editorLayer_textAlignBottom"
                  ></label>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {this.props.layer.type === "single" ? (
            <div>
              <div className="dm_editLayer_title"> Box Properties </div>
              <div className="dm_editorLayer_textboxPropertiesContainer">
                <div className="dm_editLayer_textboxContainer">
                  <label className="pure-material-textfield-outlined addPxSuffixToInput">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={Math.trunc(this.props.layer.width)}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.setState({ fontSize: e.target.value });
                      }}
                    />
                    <span>Width</span>
                  </label>
                </div>
                <div className="dm_editLayer_textboxContainer">
                  <label className="pure-material-textfield-outlined addPxSuffixToInput">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={Math.trunc(this.props.layer.height)}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.setState({ fontSize: e.target.value });
                      }}
                    />
                    <span>Height</span>
                  </label>
                </div>
              </div>
              <div className="dm_editorLayer_textboxPropertiesContainer">
              {
                  this.state.dragging?
                    <div className="droppableBox">
                      Drop File Here
                    </div>
                  :
                  ""
                }
                <div className="dm_editLayer_textboxContainer">
                  <label className="pure-material-textfield-outlined addPxSuffixToInput">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={Math.trunc(this.props.layer.position[0])}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.setState({ fontSize: e.target.value });
                      }}
                    />
                    <span>X</span>
                  </label>
                </div>
                <div className="dm_editLayer_textboxContainer">
                  <label className="pure-material-textfield-outlined addPxSuffixToInput">
                    <input
                      placeholder=" "
                      type="Textbox"
                      value={Math.trunc(this.props.layer.position[1])}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={e => {
                        this.setState({ fontSize: e.target.value });
                      }}
                    />
                    <span>Y</span>
                  </label>
                </div>
              </div>
              <div className="dm_editLayer_title"> Current Image </div>
              <div className="dm_editLayer_currentImage">
                <img width="100%" src={this.props.layer.content} />
              </div>

              <div style={{ width: "180px", margin: "10px auto" }}>
                <div className="pure-material-button-outlined">
                  Replace Image
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default EditLayer;
