import React from "react";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      helpOpen: false
    };
  }

  render() {
    return (
      <div>
        <div className="footerContainer">
          <div className="footerText">
            <span className="footerTextBold">TEAM INFOGRAPHICS</span>
            <span className="footerDot"> • </span>
            <span className="footerTextSmall">POWERED BY</span>
            <span className="footerSpace"> </span>
            <span className="footerTextBold"> FUZZY RED PANDA</span>
            <span className="footerDot"> • </span>
            <span className="footerTextSmall">COPYRIGHT Ⓒ 2020</span>
          </div>
        </div>

        {this.state.helpOpen ? (
          <div id="helpButton">
            <button
              className="helpButton helpButtonRotate"
              onClick={() => {
                this.setState({ helpOpen: false });
              }}
            >
              ?
            </button>
            <div className="helpButtonInfo helpButtonInfoAnimation">
              <div className="helpButtonInfoLine1">Need Help? Contact:</div>
              <div className="helpButtonInfoLine2">
                <a href="mailto:helpdesk@teaminfographics.com">
                  helpdesk@teaminfographics.com
                </a>
              </div>{" "}
            </div>
          </div>
        ) : (
          <div id="helpButton">
            <button
              className="helpButton"
              onClick={() => {
                this.setState({ helpOpen: true });
              }}
            >
              ?
            </button>
            <div className="helpButtonInfo">
              <div>Need Help? Contact:</div>
              <div>
                <a href="mailto:helpdesk@teaminfographics.com">
                  helpdesk@teaminfographics.com
                </a>
              </div>{" "}
            </div>
          </div>
        )}
      </div>
    );
  }
}
