import React from "react";
import Footer from "./Footer";
import axios from "axios";

export default class AddLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
      customWidth: "",
      customHeight: "",
      selected: -1,
      name: "",
      message: ""
    };
  }
  handleCustomWidth(evt) {
    const customWidth = evt.target.validity.valid
      ? evt.target.value
      : this.state.customWidth;

    this.setState({ customWidth });
  }
  handleCustomHeight(evt) {
    const customHeight = evt.target.validity.valid
      ? evt.target.value
      : this.state.customHeight;
    this.setState({ customHeight });
  }

  sendData(width, height, name){
    axios({
      method: 'post', //you can set what request you want to be
      url: process.env.REACT_APP_TIGS_API_BASE_URL + "add",
      data: {
        width,
        height,
        name,
        bgPreview: "https://tig-demo.s3.us-east-1.amazonaws.com/layout_generic_thumb.png"
      },
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.userInfo).token
      }    
    })
    .then(response => {
      this.props.history.push('/layouts');
    })
    .catch(error => {
      if (error.response.data.message) {
        this.setState({ message: error.response.data.message });
      }
    });
  }
  validateInput(){
    this.setState({message: ""})
    if(this.state.selected === 4){
      if(!Number.isInteger(parseInt(this.state.customWidth))){
        this.setState({message: "Invalid Width"})
      }
      else if(!Number.isInteger(parseInt(this.state.customHeight))){
        this.setState({message: "Invalid Height"})
      }
      else{
        if(this.state.name === ""){
          this.setState({message: "Invalid Name"})
        }
        else{
          this.sendData(this.state.customWidth, this.state.customHeight, this.state.name)
        }
      }
    }else{
      if(this.state.selected === -1){
        this.setState({message: "Please select a layout size"})
      }
      else{
        if(this.state.name === ""){
          this.setState({message: "Invalid Name"})
        }
        else{
          this.sendData(this.state.width, this.state.height, this.state.name)
        }
      }
    }
  }

  render() {
    return (
      <div className="mainContainer">
        <div className="topContainer">
          <div className="topMenuBack" onClick={()=>{ this.props.history.push("/layouts");}}> {"<"} BACK</div>
        </div>
        <div className="teamInfoLogoLong"></div>
        <div style={{ flex: 1 }}>
          <div className="addLayout_setion">
            <div className="addLayout_container">
              <div className="addLayout_box">
                <input
                  type="radio"
                  id="option_1"
                  name="select"
                  value="1"
                  onChange={() => {
                    this.setState({ width: 800, height: 800, selected: 1 });
                  }}
                />
                <label htmlFor="option_1">
                  <div className="check"></div>
                  <div className="addLayout_square">800 x 800</div>
                </label>
              </div>
              <div className="addLayout_box">
                <input
                  type="radio"
                  id="option_2"
                  name="select"
                  value="2"
                  onChange={() => {
                    this.setState({ width: 1280, height: 720, selected: 2 });
                  }}
                />
                <label htmlFor="option_2">
                  <div className="check"></div>
                  <div className="addLayout_horizontal">1280 x 720</div>
                </label>
              </div>
              <div className="addLayout_box">
                <input
                  type="radio"
                  id="option_3"
                  name="select"
                  value="3"
                  onChange={() => {
                    this.setState({ width: 720, height: 1280, selected: 3 });
                  }}
                />
                <label htmlFor="option_3">
                  <div className="check"></div>
                  <div className="addLayout_vertical">720 x 1280</div>
                </label>
              </div>
              <div className="addLayout_box">
                <input
                  type="radio"
                  id="option_4"
                  name="select"
                  value="custom"
                  onChange={e => {
                    this.setState({ selected: 4  });
                    let element = document.getElementById('addLayout_w');
                    element.focus();
                  }}
                />
                <label className="labelProps" htmlFor="option_4">
                  <div className="check"></div>
                  <div className="addLayout_custom">
                    <div className="addLayout_customTitle">CUSTOM</div>
                    <div className="addLayout_customTitle">
                      w:{" "}
                      <input
                        id="addLayout_w"
                        className="addLayout_input"
                        placeholder="####"
                        onClick={e => {
                          let element = document.getElementById('option_4');
                          element.checked = true;
                          this.setState({selected: 4});
                        }}
                        type="text"
                        pattern="[0-9]*"
                        value={this.state.customWidth}
                        onChange={(e)=>{this.handleCustomWidth(e)}}
                      />
                    </div>
                    <div className="addLayout_customTitle">
                      h:{" "}
                      <input
                        className="addLayout_input"
                        placeholder="####"
                        onClick={e => {
                          let element = document.getElementById('option_4');
                          element.checked = true;
                          this.setState({selected: 4});
                        }}
                        type="text"
                        pattern="[0-9]*"
                        value={this.state.customHeight}
                        onChange={(e)=>{this.handleCustomHeight(e)}}
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="addLayout_nameContainer">
              <label className="pure-material-textfield-outlined">
                <input
                  placeholder=" "
                  type="text"
                  onChange={e => {
                    this.setState({ name: e.target.value });
                  }}
                />
                <span>Name</span>
              </label>
            </div>
            <div className="addLayoutButtonContainer">
              <div className="pure-material-button-outlined" onClick={()=>{this.validateInput()}}>
                {" "}
                Create Layout{" "}
              </div>
              <div className="addLayoutMessage">{this.state.message}</div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
