import React from "react";
import Footer from "./Footer";
import axios from "axios";
import Loading from './Loading';



export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      message: ""
    };
  }

  checkFields() {
    this.setState({ message: <Loading /> });
    //
    //Check if empty
    if (this.state.username === "" || this.state.password === "") {
      this.setState({
        message: "Please enter username/password"
      });
    } else {
      axios
        .post(process.env.REACT_APP_USER_API_BASE_URL + "authenticate", {
          username: this.state.username,
          password: this.state.password
        })
        .then(response => {
          localStorage.setItem("userInfo", JSON.stringify(response.data));
          this.props.history.push("/layouts");
        })
        .catch(error => {
          if (!error.response) {
            this.setState({message: "Connection Error. Please check your Internet connection and try again."})
          } else {
            if (error.response.data.message) {
              this.setState({ message: error.response.data.message });
            }
          }
        });
    }
  }

  render() {
    return (
      <div className="mainContainer">
        <div className="topContainerIntro">
          <div className="topImg"></div>
        </div>
        <div className="middleContainer" style={{ flex: "1 1 0%" }}>
          <div className="teamInfoLogo"></div>
          <form>
            <div className="inputContainer">
              <label className="pure-material-textfield-outlined">
                <input
                  placeholder=" "
                  autoComplete="email"
                  onChange={e => {
                    this.setState({ username: e.target.value });
                  }}
                />
                <span>Username</span>
              </label>
            </div>
            <div className="inputContainer">
              <label className="pure-material-textfield-outlined">
                <input
                  placeholder=" "
                  type="password"
                  autoComplete="current-password"
                  onChange={e => {
                    this.setState({ password: e.target.value });
                  }}
                />
                <span>Password</span>
              </label>
            </div>
            <div className="inputContainer">
              <button
                className="pure-material-button-outlined"
                onClick={e => {
                  e.preventDefault();
                  this.checkFields();
                }}
              >
                Login
              </button>
            </div>

            <div className="loginMessage">{this.state.message}</div>
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}
